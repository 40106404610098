body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#orderon-container__form {
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: calc(1.4rem);
}

div#orderOn {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

h5#flashdata {
  font-family: Hack, Fira Code, Anonymous Pro, Inconsolata, Menlo, monospace;
  margin-top: calc(1pt + 0.4vh);
  margin-bottom: calc(1pt + 0.4vh);
}

ul#shopping-list {
  margin-top: calc(1pt + 0.4vh);
  margin-bottom: calc(1pt + 0.4vh);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: calc(1.4rem);
}

ul#shopping-list li {
  list-style-type: upper-roman;
}